import { ref, onMounted } from "@vue/composition-api";
import { listAddressbook } from "@/api/logistics.api";
import { listAddressbook as listClientAddressBook } from "@/api/client-operation.api";

export function useLogisticsAddressBook(forSelect = false) {
  const addressbooks = ref([]);

  onMounted(() => {
    loadAddressbook();
  });

  const loadAddressbook = async () => {
    const response = await listAddressbook();
    addressbooks.value = response.data;
  };

  const refresh = () => loadAddressbook();

  let columns;
  let options;

  if (forSelect) {
    columns = ["company", "address", "unit_no", "contact_person", "contact_number", "remark"];
    options = {
      headings: {
        company: "Company",
        address: "Address",
        unit_no: "Unit No.",
        contact_person: "Contact Person",
        contact_number: "Contact Number",
        remark: "Remark"
      },
      skin: "b-table table table-bordered table-hover",
      tabbable: false,
      filterable: true,
      sortable: []
    };
  } else {
    columns = ["company", "address", "unit_no", "contact_person", "contact_number", "remark", "action"];
    options = {
      headings: {
        company: "Company",
        address: "Address",
        unit_no: "Unit No.",
        contact_person: "Contact Person",
        contact_number: "Contact Number",
        remark: "Remark",
        action: ""
      },
      skin: "b-table table table-bordered",
      tabbable: false,
      filterable: false,
      sortable: []
    };
  }

  return {
    addressbooks,
    columns,
    options,
    refresh
  };
}

export function useClientAddressBook(forSelect = false) {
  const addressbooks = ref([]);

  onMounted(() => {
    loadAddressbook();
  });

  const loadAddressbook = async () => {
    const response = await listClientAddressBook();
    addressbooks.value = response.data;
  };

  const refresh = () => loadAddressbook();

  let columns;
  let options;

  if (forSelect) {
    columns = ["company", "address", "unit_no", "contact_person", "contact_number", "remark"];
    options = {
      headings: {
        company: "Company",
        address: "Address",
        unit_no: "Unit No.",
        contact_person: "Contact Person",
        contact_number: "Contact Number",
        remark: "Remark"
      },
      skin: "b-table table table-bordered table-hover",
      tabbable: false,
      filterable: true,
      sortable: []
    };
  } else {
    columns = ["company", "address", "unit_no", "contact_person", "contact_number", "remark", "action"];
    options = {
      headings: {
        company: "Company",
        address: "Address",
        unit_no: "Unit No.",
        contact_person: "Contact Person",
        contact_number: "Contact Number",
        remark: "Remark",
        action: ""
      },
      skin: "b-table table table-bordered",
      tabbable: false,
      filterable: false,
      sortable: []
    };
  }

  return {
    addressbooks,
    columns,
    options,
    refresh
  };
}
