<template>
  <div class="card card-custom gutter-b">
    <div class="card-header">
      <div class="card-title">
        <h3 class="card-label">
          Address Book
          <span class="d-block text-muted pt-2 font-size-sm">
            Save your frequently used address here for easy creation of jobs
          </span>
        </h3>
      </div>
      <div class="card-toolbar">
        <!--begin::Button-->
        <router-link
          to="/client/address-book/create"
          tag="button"
          class="btn btn-primary font-weight-bolder"
        >
          <span class="svg-icon svg-icon-md">
            <inline-svg src="/media/svg/icons/Design/Flatten.svg"></inline-svg>
          </span>
          New Address
        </router-link>
        <!--end::Button-->
      </div>
    </div>
    <div class="card-body">
      <v-client-table
        :columns="columns"
        v-model="addressbooks"
        :options="options"
      >
        <div slot="action" slot-scope="props">
          <router-link
            :to="{
              name: 'UpdateClientAddressbook',
              params: { id: props.row.id, addressObject: props.row },
            }"
            tag="a"
            class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
          >
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <!--begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/Communication/Write.svg-->
              <inline-svg
                src="/media/svg/icons/Communication/Write.svg"
              ></inline-svg>
              <!--end::Svg Icon-->
            </span>
          </router-link>

          <a
            @click="deleteAddressbook(props.row.id)"
            class="btn btn-icon btn-light btn-hover-primary btn-sm"
          >
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <!--begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/General/Trash.svg-->
              <inline-svg src="/media/svg/icons/General/Trash.svg"></inline-svg>
              <!--end::Svg Icon-->
            </span>
          </a>
        </div>
      </v-client-table>
    </div>
  </div>
</template>

<script>
import { useClientAddressBook } from "@/utils/address-book";
import { deleteAddressbook as deleteApi } from "@/api/client-operation.api";
import Swal from "sweetalert2";

export default {
  name: "ListClientAddressbook",
  setup() {
    const { addressbooks, columns, options, refresh } = useClientAddressBook(
      true
    );

    const deleteAddressbook = (id) => {
      Swal.fire({
        title: "Confirm delete?",
        text: "You won't be able to revert this! ",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await deleteApi(id);
          refresh();
          Swal.fire("Deleted!", "This address have been removed", "success");
        }
      });
    };

    return {
      columns,
      options,
      addressbooks,

      deleteAddressbook,
    };
  },
};
</script>
